.values-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
 
    align-items: center;
    margin-left: 1rem;
    
}
.values-sub-container{
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}
.values-heading-h1{
    font-size: 3rem;
}
.div-des{
    font-size: 1rem;
    margin-top: 1.2rem;
    text-align: left;
    text-wrap: wrap;
    
}

.boxes{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;

}
.div-professionalism{
    
    font-size: 2rem;
    width: 100%;
    height: 80%;
    text-align: left;
   
    animation: backInLeft; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; 
    /* animation-delay: 5s; */
}
.div-professionalism div{
    width: 30%;
    height: 0.5rem;
    background-color: #d8ccbc;

}
.div-partnership{

    font-size: 2rem;
    width: 100%;
    height: 80%;
    text-align: left;
   
    animation: backInLeft; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; 
}
.div-partnership div{
    width: 30%;
    height: 0.5rem;
    background-color: #79c8a4;
}
.div-passion{
   
    font-size: 2rem;
    width: 100%;
    height: 80%;
    text-align: left;
   
    animation: backInLeft; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; 
}
.div-passion div{
    width: 30%;
    height: 0.5rem;
    background-color:#bd3b39;}

.div-performance{
    
    font-size: 2rem;
    width: 100%;
    height: 80%;
    text-align: left;
   
    animation: backInLeft; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; 
}
.div-performance div{
    width: 30%;
    height: 0.5rem;
    background-color: #28aee4;
}



@media (max-width:767px) {
    .values-sub-container{
    
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 1.5rem;
        
    }
    .values-heading-h1{
        font-size: 2rem;
    }
    .boxes{
        margin-bottom: 1rem;
    }
     
   }
   @media  (min-width: 768px) and (max-width: 1023px) {
     .values-sub-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
     }
     .div-professionalism{
        width: 30%;
        margin-bottom: 0;
     }
     .div-partnership{
        width: 30%;
        margin-bottom: 0;
     }
     .div-passion{
        width: 30%;
        margin-bottom: 0;
     }
     .div-performance{
        width: 30%;
        margin-bottom: 0;
     }
     .boxes{
        margin-bottom: 1rem;
     }
     .div-des{
        font-size: xx-large;
     }
   }