.wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 32px;
    width: 88.2%;
    margin: 0rem auto 0rem auto;
    min-height: 100vh;
}
.div1{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
}
.div2{
  width: 50%;
  .book{
    margin-right: 1rem;
  }
}
form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input::placeholder,
  textarea::placeholder {
    color: #888;
  }
  
  button {
    background-color:  #134d65;
    color: #fff;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  

  
  /* Add additional styling for better visual appeal */
  
  .nameinput {
    /* Specific styling for the name input if needed */
  }
  
  /* Style labels for better alignment */
  
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  /* Add responsive styling if needed */
  
  @media (max-width: 767px) {
   .wrapper{
     flex-direction: column;
   }
   .hero-head{
    text-align: center;
    width: 100%;
   }
   .hero-para{
    text-align: center;
    font-size: 1rem;
 
    width: 100%;
   }
   .drop{
    text-align: center;
    width: 100%;
   }
   .div2{
    width: 100%;
   }
    form {
      max-width: 100%;
      padding: 0;
    }
  }
  