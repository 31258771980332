.terms-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 5rem;
    padding: 15px;
}
.heading-main{
    font-size: 32px;
    font-weight: 600;
}
.term-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    gap: 16px;
}
.title{
    font-size: 24px;
}
.content{
   font-size: 16px;
}
@media (max-width:767px){
    .heading-main{
        font-size: 24px;
    }
    .term-div{
        gap:8px;
    }
    .title{
        font-size: 16px;
    }
    .content{
        font-size: 12px;
    }
}