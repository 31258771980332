.question{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    gap:16px;
    flex-wrap: wrap;
}
.imgdiv{
    background-color: #134d65;
    cursor: pointer;
    border-radius: 2rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    >span{
        color: white;
       font-weight: 600;
       font-size: 1.5rem;
       text-wrap: nowrap;
    }
  
}
.questions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:16px;
}
.divq{
   
   width: 100%;

   padding: 1rem;
   border-radius: 5px;
   text-wrap: wrap;
   overflow: hidden;
  
}
.divq span{
    color: #134d65;
margin-bottom: 0;
font-size: 1.1rem;
}
 @media(max-width:767px){
    .imgdiv{
        >span{
            font-size: 1rem;
        }
    }
 }