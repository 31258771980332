.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1rem ;
    height: 3rem;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
   overflow-y: hidden;
   position: fixed;
   background-color: white;
   z-index: 1;
}
.logo_img img{
    width: 10rem;
    height: 2rem;
}
.mob_view{
    display: none;
}


@media (max-width:767px){

    .nav_items{
        display: none;
    }
    .mob_view{
        display: inline-block;
        width: auto;
        height: auto;
        background-color:#e6edf0c2;
        color: white;
        margin-right: 5px;
    }
    .nav_items{
        display: none-;
    }
}