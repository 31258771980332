.main-div {
  display: flex;
  flex-direction: column;
  

  width: 100vw;
  height: 100vh;
}
.banner-div{
    /* background-image: url('../image/Boston\ Skyline-03.png'); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90vh;
    opacity: 0.9;
    margin-top: 3rem;
    color: '#565f66';
    text-shadow: black;
    background-color: #e6edf0c2;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
}
.img-div{
  width: 30%;
  margin-bottom: 5rem;
  margin-right: 5rem;
}
.banner-heading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
 
    width: 50%;
    height: 100%;

    ; /* Adjust height as needed */
 
}

.main-heading{
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  text-wrap: nowrap;
}
.sub-heading{
  font-size: 1.5rem;
  text-align: left;
 
}
.sub-heading span{
  font-weight: bold;
}
.banner-heading button{
  background-color: #28aee4;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5rem;
}
.banner-heading button:hover{
  background-color: #134d65;
}
.banner-subheading{
    
    color: #134d65;
    text-align: center;
    font-size: 2rem;
}
.subheading-h1{
  animation: bounce; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 4s; 
    /* font-style: 'Caveat'  !important */
}
.subheading-h1 span{
  font-weight: bolder;
}
.main-div{
    display: flex;
    flex-direction: column;
}

/* .banner-subheading h1 {
    animation: fadeIn 1s ease-in forwards; 
    opacity: 0; 
  }
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  } */

  @media (max-width:767px) {
   .banner-heading{
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 1rem
   }
   .main-heading{
    font-size: 1.7rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
   }
   .sub-heading{
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: center;

   }
   .img-div{
    display: none;
   }
   .banner-heading button{
    font-size: 1rem;
    
   }
   .banner-div{
   
    background-size: cover;
    flex-direction: column-reverse;
   
   }
   .subheading-h1{
    padding: 0.5rem;
   }
   .banner-subheading {
   
    height: 2rem;
    font-size: 1rem;

}
    
  }
  @media  (min-width: 768px) and (max-width: 1023px) {
    .banner-heading {
      color: white;
      text-shadow: black;
      background-color: #3f4a4fc2;
      font-size: 3rem;
      width: 90vw;
      margin-bottom: 5rem;
      font-weight: bolder;
      font-size: xx-large;
  }
     .banner-div {
    
      width: 100%;
      height: auto;
      opacity: 0.9;
     
      background-size: cover;
  }
  .banner-subheading {
    background-color: #FFC000;
    color: #3F4A4F;
    text-align: center;
    font-size: larger;
}
  }