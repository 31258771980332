/* .ListItem{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
    gap:1rem
} */
.container{
    display: flex;
    height: 80vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   
    background-color: #e6edf0c2;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
}
.sub-container-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 60vh;
}
.heading{
    font-weight: 500;
    font-size:3rem;
    margin-bottom: 2rem;
}
.para{
    font-size: 1.25rem;
}
/* .sub-container-1 img{
    width: 100%;
} */
.icons{
    /* font-size: 200px */
}
.key-features{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
}
.sub-container-2{
    width: 50%;
    >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: start;
        gap: 15px;
    }
}
.container-2{
    width: 100%;
    margin: auto;
    display: flex;
  justify-content: center; /* Define row height */
  gap: 10px; 
  margin-top: 2rem;
  margin-bottom: 2rem;
  row-gap: 55px;
  column-gap: 55px;
}

.ListItem span{
    font-size: 1rem;
    margin: 0.2rem;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
   
    align-items: center;
}
.container-3{
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    gap: 1rem;
}
.container-3-sub{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.container-3-sub-div1{
    width: 20vw;
    height: auto;
    display: flex;
    justify-content:center ;
    align-items: center;
   
  
}
.container-3-sub-div1 img{
    border-radius: 50%;
    max-width: 60%;
    max-height: 100%;
    filter: grayscale(100%);
    background: linear-gradient(to left top, #134d65, #134d65);
}
.container-3-sub-div2{
  width: 75vw;
  margin-right: 1rem;
  overflow: auto;
}
.container-3-sub-div2 p{
    font-size: 1rem;
    word-spacing: 0.3rem;
    text-align: left;
    line-height: 1.5rem;
     text-wrap: wrap;
    
}
@media  (min-width: 768px) and (max-width: 1023px){
   .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    clip-path: none;
   }
   .sub-container-1{
    width: 100%;
   }
   .sub-container-1 img{
     width: 80%;
   }
   .sub-container-2{
    width: 100%;
   }
   .sub-container-2 h1{
    text-align: center;
   }
   .para{
    font-size: 1.8rem;
   }
 

}

@media (max-width:767px){
    .container{
        margin-top: 3rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        clip-path: none;
    }
    .sub-container-1{
        width: 100%;
       }
    .sub-container-2{
        width: 100%;
    }
    .heading{
        text-align: center;
        font-size: 2rem;
    }
    .para{
        text-align: center;
        font-size: 1rem;
    }
    .container-2{
        display: flex;
       flex-wrap: wrap;
       row-gap: 10px;
       column-gap: 5px;
    }
    .ListItem{
        width: 43%;
    }
    .container-3-sub {
        flex-direction: column;
        margin-top: 0;
    }
    .container-3-sub-div1 {
        width: 50%;
        height: 50%;
        display: none;
    }
    .container-3-sub-div2 {
        width: 100%;
        padding: 10px;
    }
    .container-3-sub-div2 p {
        font-size: 1rem;
     text-align: center;
    
    }
    .key-features{
        font-size: 1.5rem;
        text-align: center;
    }
}