.footer-main{
    /* position:absolute; */
    /* position:fixed; */
    margin-top: auto;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items:  center ;
    padding: 1rem;
    background-color: #e6edf0c2;
}
.reserved1{
  
}
.reserved2{
    text-align: end;
}
@media (max-width:767px){
    .footer-main{

    }
}