.service-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 100%;
    
    /* flex-wrap: wrap; */
}
.boxesss{
    width: 100vw;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    grid-row-gap: 30px;
    margin-left: 0rem;
    padding: 1rem;
    min-height: 100vh;
}
.service-title{
    margin-top: 3rem;
    font-size: 2rem;
    width: 100%;
    font-size: 800;
    padding: 1rem;
}
.service-title span{
    font-size: 1rem;
}
.faas{
    font-size: 2rem;
    font-weight: 500;
}
.logo-des{
    background-color: #134d65;
   display: flex;
   justify-content: center;
   align-items: center;
   clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.logo-des-p{
    color: white;
    font-size: 2rem;
    margin-bottom:1rem ;
}
.logo-des-a{
    color: #134d65;
    font-size: 2.5rem;
    margin-bottom:1rem ;  
}

.service-sub-title{
    font-size: 1rem;
    width: 100%;
    padding: 1rem;
}
.service-img{
    width: 100%;
    height: 100%;
 
}
.service-paper{
    display: flex;
   width: 75%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 5%;
    cursor: pointer;
    height: 250px; 
    box-shadow: 3px 3px 3px 3px rgb(207, 207, 207);
}
.react-card-front{
    display: flex;
    justify-content: center;
}
.react-card-back{
    display: flex;
    justify-content: center;
}
.service-paper div{
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 50%;
}
.service-heading{
    width: 80%;
    font-size:1.2rem;
    text-align: center;
    text-wrap: wrap;
    font-weight: 400;
    /* margin-right: 50%; */
}
.back-side{
    display: flex;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 5%;
    width: 75%;
    height: 250px; 
    box-shadow: 3px 3px 3px 3px rgb(207, 207, 207);
}
.back-side p{
   width: 80%;
   line-height: 5px;
   line-height: 1rem;
   text-align: center;
}
.conclusion{
    width: 100%;
    background-color: #e6edf0c2;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    color: white;
    display: flex;
    height: 60vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    gap: 2rem;
}
.conclusion-text{
   
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
    gap: 1rem;
   >p{
    font-size: 2rem;
    font-weight: 500;
    text-align: left;
    color: #134d65;
    >span{
        font-size: 1rem;
    }
   }
}
.conclusion-btn{
    background-color: #28aee4;
    color: #134d65;
    border-radius: 5rem;
    font-size: 1.5rem;
    padding: 1rem;
    cursor: pointer;
}

@media (max-width:767px){
    .boxesss{
        grid-template-columns: repeat(1, 1fr); 
        gap: 32px;
        margin-top: 0;
    }
    .service-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;}
    .service-paper{
      width: 80%;
   
    }
    .service-title{
        margin-top: 0;
        font-size: 1.5rem;
        text-align: center;
    }
    .service-title span{
        font-size: 1.5rem;
    }
 
    .back-side p{
        text-align: center;
        font-size: 15px;
     }
    .service-heading{
        font-size: 1rem;
    }
    .logo-des{
        margin-top: 0;
        flex-direction: column;
        padding: 1rem;
        clip-path: none;
        margin-bottom: 0;
    }
    .logo-des-p{
        font-size: 1rem;
        text-align: center;
    }
    .logo-des-a{
        font-size:1.5rem;
    padding: 1rem;     }
    .conclusion{
        flex-direction: column;
        justify-content: flex-start;
    }
   .img-div1{
    display: none;
   }
   .conclusion-text{
    padding: 1rem;
    >p{
        font-size: 1.5rem;
        text-align: center;
    }
    
   }
   .conclusion-btn{
    font-size: 1rem;
   }
}

@media  (min-width: 768px) and (max-width: 1023px){

}