.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* Other general styles for your app */
  width: 100%;
  margin: auto;
}

/* .background-image {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./image/bg.jpg'); 
  background-size: cover;
  opacity: 0.5;
  
  z-index: -1; 
} */

.content {

}